.callout-listing {
    padding: 100px 0 0 0;
    background-position: center center;
    background-position-y: 0;
    background-repeat: no-repeat;
    background-size: 100%;

    @include mobile-only {
        padding-top: 40px;
        padding-left: 30px;
        padding-right: 30px;
        background-position: 65% 100%;
    }

    @include tablet-only {
        padding-top: 60px;
    }

    .callout-list-heading {
        font-size: 23px;

        @include tablet {
            font-size: 32px;
        }

        @include desktop-large {
            font-size: 41px;
        }
    }

    .generic-content {
        margin-bottom: 50px;

        @include tablet {
            margin-bottom: 35px;
        }

        @include mobile-only {
            margin-bottom: 44px;
        }
    }

    .report-list {
        margin-bottom: 20px;

        h3 {
            margin-bottom: 15px;

            @include mobile-only {
                margin-bottom: 10px;
                margin-top: -68px;
            }
        }

        ul {
            display: block;


            li {
                @include tablet {
                    img {
                        width: 100px;
                    }

                    .details {
                        padding-left: 5px;
                        font-size: 16px;
                        color: black;
                        line-height: 1;
                        width: calc(100% - 100px);
                    }

                    @include portrait-view {
                        width: 250px;
                    }

                    @include landscape-view {
                        width: 278px;
                    }
                }

                @include desktop-large {
                    width: 280px;

                    .details {
                        line-height: 1;
                        font-size: 16px;
                        width: calc(100% - 100px);
                    }
                }
            }
        }
    }

    .evaluator-list {
        margin-bottom: 20px;
    }

    .eval-reports {
        .report-list {
            ul {
                li {
                    .details {
                        @include tablet {
                            width: 100%;
                            padding-left: 0;
                        }
                    }

                    img {
                        @include desktop-large {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}
.Generic-callout {
    margin-bottom: 20px;
}
.container-fluid {
    @include mobile-only {
        .container {
            padding-left: 0;
            padding-right: 0;
        }

        &.evalution-report {
            padding-left: 30px;
            padding-right: 30px;
        }

        &.whats-new-wrapper {
            padding-left: 30px;
            padding-right: 30px;
        }

        &.site-search {
            padding-left: 30px;
            padding-right: 30px;
        }

        &.imf-protection {
            padding-left: 30px;
            padding-right: 30px;
        }

        &.Generic-callout {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    @include desktop-large {
        &.evalution-report {
            padding-top: 30px;
        }
    }
}
.arb {
    .callout-listing {
        .evaluator-list {
            ul {
                padding-right: 0;

                li {
                    margin: 0 0 15px 15px;
                }
            }
        }
    }
}
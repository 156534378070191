.subsection {
    h3 {
        background-repeat: no-repeat;
        background-position: left bottom 2px;
        /*background-size: 28px 28px;*/
        padding-left: 40px;
        padding-top: 9px;
        margin-bottom: 20px;

        @include desktop-large {
            padding-top: 12px;
        }

        @include mobile-only {
            padding-top: 15px;
        }
    }

    .report-list {

        ul {
            justify-content: flex-start;

            li {
                width: 150px;
                margin-right: 2%;
                min-height: 250px;

                @include mobile-only {
                    margin-right: 5%;
                    margin-bottom: 15px;
                    width: 47.5%;
                    min-height: 130px;
                }

                .details {
                    margin-top: 10px;

                    .title {
                        line-height: 1.25;
                    }
                }
            }
        }
    }
}
.evalution-report .subsection .report-list ul li:nth-child(2n) {
    @include mobile-only{ 
        margin-right: 0;
    }
}
.arb {
    .subsection {
        h3 {
            background-position: right center;
            padding-right: 40px;
            padding-left: 0;
        }

        ul {
            li {
                margin-right: 0;
                margin-left: 20px;
            }
        }
    }
}

$font-size-tablet: 14px;
$font-size-desktop: 16px;

.experience-editor {
    .header-desktop {
        top: auto;
        position: absolute;
    }

    .main {
        padding-top: 155px;
    }

    .header-mobile {
        position: relative;
    }
}

.preview .header-desktop {
    top: auto;
    position: absolute;
    margin-top: -100px;
}

.header-desktop {
    position: fixed;
    z-index: $z-index-header;
    top: 0;
    width: 100%;
    @include transition(background-color 100ms ease);

    @include mobile-only {
        display: none;
    }

    @include tablet {
        height: $header-height-tablet;
    }

    @include desktop-only {
        height: $header-height-desktop;
    }

    @include tablet-only {
        height: $header-height-tablet-only;
    }

    .logo {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        max-width: 272px;
        width: 20%;

        a {
            display: block;
            width: 100%;
        }

        img {
            width: 100%;
        }

        .upper-logo {
            display: block;
            margin-top: 30px;
            max-width: 80%;
            padding-right: 10px;
        }

        .lower-logo {
            display: block;
            max-width: 40%;
            margin-top: 20px;
            padding-right: 10px;
        }
    }

    .menu {
        position: relative;
        top: 68%;
        transform: translateY(-50%);

        @include tablet-only {
            width: 555px;
            top: 56%;
        }

        @include tablet {
            width: 987px;
            max-width: unset;
        }

        @include desktop {
            width: 1200px;
        }

        @media (min-width: 1200px) and (max-width: 1380px) {
            margin-left: 20.6%;
        }

        .primary {
            list-style-type: none;
            padding-left: 0;
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0;

            > li {
                font-family: "MuseoSans-300";
                font-style: normal;
                font-weight: 100;
                display: inline-block;
                margin-right: 35px;
                text-transform: uppercase;
                float: left;
                display: inline-block;
                position: relative;

                @include tablet {
                    font-size: $font-size-tablet;
                    margin-right: 35px;

                    @media screen and (orientation:portrait) {
                        margin-right: 25px;
                        font-size: 13px;
                    }
                }

                @include tablet-only {
                    font-size: 14px;
                    margin-right: 14px;
                }

                @include desktop-large {
                    font-size: $font-size-desktop;
                    margin-right: 35px;
                }

                &:hover {
                    color: $orange;

                    a {
                        color: $orange;
                    }

                    .secondary {
                        &.hoverHeader {
                            display: block;
                        }
                    }
                }

                &.active {
                    color: $orange;

                    a {
                        color: $orange;
                    }
                }

                a {
                    color: black;
                    text-decoration: none;

                    @media (min-width: 769px) and (max-width: 850px) {
                        font-size: 12px;
                    }

                    @media (min-width: 1200px) and (max-width: 1380px) {
                        font-size: 15px;
                    }
                }

                .secondary {
                    @include global-shadow;
                    z-index: 999;
                    font-size: 0;
                    display: none;
                    position: absolute;
                    top: 100%;
                    padding-left: 0;
                    list-style-type: none;
                    list-style: none;
                    border-top: 8px solid $orange;
                    background-color: white;
                    padding: 5px;
                    min-width: 200px;

                    &.xpEditShow {
                        display: block;
                    }



                    li {
                        font-family: "MuseoSans-300";
                        font-style: normal;
                        font-weight: 100;
                        color: black;
                        padding-left: 15px;
                        position: relative;
                        list-style: none;

                        @include tablet-only {
                            font-size: 12px;
                        }

                        @include desktop {
                            font-size: 14px;
                        }

                        &:hover {
                            color: $orange;

                            a {
                                color: $orange;
                            }
                        }

                        a {
                            color: black;

                            &:before {
                                content: ">";
                                position: absolute;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }

        .chat-toggle {
            @include MuseoSans700;
            display: inline-block;
            vertical-align: middle;
            color: black;
            text-transform: uppercase;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: left 15px center;
            background-image: url("../../img/icon-chat.png");
            padding-left: 50px;
            font-weight: 700;


            @include tablet {
                font-size: $font-size-tablet;

                @media screen and (orientation:portrait) {
                    font-size: 13px;
                    padding-left: 4%;
                    margin-left: 0;
                }
            }

            @include tablet-only {
                margin-left: 0;
            }

            @include desktop-large {
                font-size: $font-size-desktop;
            }

            span {
                cursor: pointer;
                color: $black;

                @media (min-width: 769px) and (max-width: 850px) {
                    font-size: 12px;
                }

                @media (min-width: 1200px) and (max-width: 1380px) {
                    font-size: 15px;
                }
            }

            &:hover {
                span {
                    color: $orange;
                }
            }
        }

        .desktop-search {
            position: relative;
            display: inline-block;

            @include tablet-only {
                width: 11%;
                height: 25px;
            }

            @include desktop-only {
                width: 16%;
            }

            .search-button {
                top: 3px;
                right: 12px;
                background-color: white;
                z-index: 99;

                @include tablet-only {
                    top: 2px;
                    background-size: 17px;
                }

                @include desktop-only {
                    right: 5px;
                    padding-left: 30px;
                }
            }
        }
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }

    .chat-toggle {
        span {
            @media (min-width: 769px) and (max-width: 850px) {
                font-size: 12px;
            }
        }
    }
}

.header-mobile {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1050;
    height: 72px;

    @include tablet {
        display: none;
    }

    .header-mobile-inner {
        position: relative;

        .site-logo-link {
            position: absolute;
            top: 10px;
            left: 15px;
        }

        .imf-seal-img {
            margin-top: 10px;
            margin-left: 10px;
        }

        .page-overlay {
            height: 100%;
            position: absolute;
            z-index: 999;
            right: -100%;
            display: none;
            max-width: 100vw;

            @include mobile-only {
                display: block;
                width:100vw !important;
            }
        }

        @include tablet {

            @include landscape-view {
                display: none;
            }
        }

        .hamburger {
            font-size: 30px;
            cursor: pointer;
            float: right;
            margin-right: 15px;
            margin-top: 10px;

            .hamburger-box {
                height: 4px;
                width: 40px;
                background-color: orange;
                margin-bottom: 8px;
                display: block;
            }
        }

        .hamburger-sidenav {
            position: absolute;
            right: -100%;
            z-index: 1;
            top: 0;
            background-color: white;
            overflow-x: hidden;
            transition: 0.5s;
            z-index: 9999;
            opacity: .92;

            .sticky-header {
                height: 72px;
            }

            ul {
                list-style: none;
                padding: 0;

                &.primary {
                    & > li {
                        border: none;
                        border-top: 1px solid $border-gray;
                        padding-top: 10px;

                        @include tablet-only {
                            margin-top: 15px;
                        }
                    }
                }


                &.secondary {
                    & > li {
                        padding-left: 15px;

                        a {
                            padding-top: 5px;
                            padding-bottom: 5px;

                            &:before {
                                content: ">";
                                position: absolute;
                                left: 18px;
                            }
                        }
                    }
                }

                .closebtn {
                    position: absolute;
                    top: 10px;
                    right: 8px;
                    background-size: 22px 22px;
                    background-repeat: no-repeat;
                }

                li {
                    box-shadow: none;

                    a {
                        padding: 10px 0 10px 20px;
                        text-decoration: none;
                        font-size: 20px;
                        color: $black;
                        display: block;

                        &.menu-link {
                            text-transform: uppercase;
                            color: $black;
                            padding-bottom: 0;
                            padding-bottom: 0;
                            padding-top: 0;
                            display: inline-block;

                            .right-arrow {
                                background-image: url($img-path + "menu-arrow-right.png");
                                background-repeat: no-repeat;
                                height: 15px;
                                width: 7px;
                                float: right;
                                display: block;
                                margin-top: 6px;
                                margin-right: 35px;
                                @include transform(rotate(90deg));
                                @include transition(transform .2s ease-in-out);
                            }

                            &.collapsed {
                                color: $black;

                                .right-arrow {
                                    @include transform(rotate(0deg));
                                    margin-right: 35px;

                                    @media (max-width: 340px) {
                                        margin-right: 20px;
                                    }
                                }
                            }
                        }
                    }

                    &.mobile-search {
                        padding: 10px 0 10px 13px;
                        position: relative;
                        font-size: 20px;
                        margin-top: 5px;
                    }

                    &.chat-content {
                        padding-top: 0;
                        margin-top: 0;
                    }

                    .right-arrow {
                        background-image: url(../../img/menu-arrow-right.png);
                        background-repeat: no-repeat;
                        height: 15px;
                        width: 7px;
                        float: right;
                        display: block;
                        margin-top: 5px;
                        margin-right: 35px;
                        -o-transform: rotate(90deg);
                        -moz-transform: rotate(90deg);
                        -ms-transform: rotate(90deg);
                        -webkit-transform: rotate( 90deg );
                        transform: rotate( 90deg );
                        -o-transition: transform 0.2s ease-in-out;
                        -moz-transition: transform 0.2s ease-in-out;
                        -webkit-transition: transform 0.2s ease-in-out;
                        transition: transform 0.2s ease-in-out;

                        &.collapsed{
                            -o-transform: rotate(0deg);
                            -moz-transform: rotate(0deg);
                            -ms-transform: rotate(0deg);
                            -webkit-transform: rotate( 0deg );
                            transform: rotate( 0deg );
                            margin-right: 35px;
                        }
                    }

                    .chat-toggle {
                        cursor: pointer;
                        background-color: $orange;
                        background-image: url($img-path + "mobile-chat-icon.png");
                        background-repeat: no-repeat;
                        background-position: right 21px center;

                        span {
                            text-transform: uppercase;
                            color: $white;
                            font-weight: 700;
                        }

                        @include tablet {
                            display: none;

                            @include portrait-view {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        .closebtn {
            position: absolute;
            top: 10px;
            right: 8px;
            background-size: 22px 22px;
            background-repeat: no-repeat;
        }
    }



    a {
        &:hover {
            text-decoration: none;
        }
    }
}

#siteSearchInput {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    height: 30px;
    outline: none;
    border: 1px solid $zircon-gray;
    margin-right: 10px;

    @include mobile-only {
        width: 93%;
        outline: none;
        border: 1px solid $light-gray;

        @include landscape-view {
            width: 96%;
        }
    }

    @include tablet {
        font-size: 14px;
        width: 98%;
    }

    @include tablet-only {
        width: 98%;
        font-size: 13px;
    }

    @include desktop-large {
        width: 295px;
        font-size: 16px;
    }
}

.search-button {
    background-image: url($img-path + "icon-magnify.png");
    background-position: right 5px center;
    background-repeat: no-repeat;
    position: absolute;
    right: 18px;
    top: 14px;
    display: block;
    z-index: 999999;
    background-size: 21px;
    height: 24px;
    width: 26px;
    font-size: 32px;

    @include tablet {
        right: 33px;
    }
}

.arb {
    .desktop-search {
        margin-right: 35px;
    }

    #siteSearchInput {
        padding-right: 45px;
    }

    .logo {
        right: 0;
        left: auto;
    }

    .header-polygon {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    .chat-toggle {
        span {
            background-position: left 21px center !important;
            padding-right: 30px !important;

            @include mobile-only {
                padding-right: 50px !important;
            }
        }
    }

    .site-logo-link {
        right: 15px !important;
        left: auto !important;
    }

    .hamburger {
        margin-left: 15px;
        float: left !important;
    }

    .hamburger-sidenav {
        left: -100%;
        right: unset !important;

        .primary {
            padding-right: 0;

            li {
               &:last-child {
                    margin-right: 0;
                }

                a {
                    padding-right: 20px !important;

                    .right-arrow {
                        float: left !important;
                        transform: rotate(90deg) !important;
                    }

                    &.collapsed {

                        .right-arrow {
                            transform: rotate(180deg) !important;
                        }
                    }
                }

                ul {
                    list-style: none;

                    li {
                        list-style: none;

                        a {
                            background-image: none !important;
                            padding-left: 0 !important;
                            list-style: none;

                            &:before {
                                content: ">";
                                margin-left: 8px;
                            }
                        }

                        @supports (-ms-ime-align: auto) {
                            list-style: none !important;
                        }
                    }
                }
            }
        }
    }

    .page-overlay {
        left: -100%;
        right: auto !important;
    }

    .header-desktop {
        .primary {
            padding-right: 0;

            li {
                a {
                    font-size: 17px;
                }

                &:last-child {
                    margin-right: 0;
                }

                .secondary {
                    li {
                        &:before {
                            content: "";
                        }
                    }

                    a {
                        margin-right: 5px;

                        &:after {
                            content: ">";
                            float: right;
                        }
                    }
                }
            }
        }

        .menu .chat-toggle {
            padding-left: 0;
            padding-right: 42px;
            background-position: right 32px center;
        }
    }

    .header-mobile {
        .header-mobile-inner {
            .hamburger-sidenav {
                ul {
                    &.secondary {
                        > li {
                            padding-right: 15px;
                            padding-left: 0;
                        }

                        a:before {
                            left: auto;
                            right: 18px;
                        }
                    }

                    li {
                        .right-arrow {
                            margin-right: 0;
                            margin-left: 35px;
                        }

                        .collapsed.right-arrow {
                            margin-right: 0;
                            margin-left: 35px;
                        }

                        &.chat-content {
                            a {
                                background-position: left 21px center;
                            }

                            span {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .closebtn {
                right: auto;
                left: 8px;
            }
        }
    }
}

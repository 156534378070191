.footer {
    background-color: $orange;
    color: white;
    padding: 25px $gutters;

    @include mobile-only {
        padding-left: 30px;
        padding-right: 30px;
    }

    a {
        color: white;
    }

    .breadcrumbs {

        @include mobile-only {
            margin-bottom: 50px;
        }

        a {
            color: white;
        }
    }

    .left,
    .right {
        float: left;
        width: calc(45% - 5px);

        &.left {
            padding-right: 5px;

            @include mobile-only {
                display: block;
                padding-right: 15px;
            }
        }

        &.right {
            padding-left: 5px;
        }

        @include mobile-only {
            display: block;
        }

        .inner-left,
        .inner-right {
            display: block;
            width: 100%;
            float: none;

            &.inner-left {
                width: 100%;

                @include desktop {
                    width: 59%;
                    padding-right: 1%;
                }
            }

            &.inner-right {
                width: 100%;

                @include desktop {
                    width: 40%;
                }
            }

            @include desktop {
                float: left;
            }
        }
    }

    .location-title {
        margin-bottom: 20px;
        font-weight: bold;

        .contact-info {
            font-family: "MuseoSans-700";
            text-transform: uppercase;
            font-size: 18px;
        }

        .italic {
            font-style: italic;
        }

        p {
            font-size: 14px;
            font-family: "MuseoSans-500";

            .break {
                @include mobile-only {
                    display: none;
                }
            }
        }
    }

    .address,
    .email,
    .telephone {
        padding: 0;
        margin-bottom: 10px;

        &.address {
            @include tablet {
                padding-right: $gutters;
            }
        }

        img {
            float: left;
            max-width: 30px;

            @include mobile-only {
                margin-right: 10px;
            }
        }

        .text-content {
            width: calc(100% - 35px);
            float: right;


            @include mobile-only {
                font-size: 14px;
            }
        }
    }

    .copyright {
        text-align: left;
        font-size: 12px;
        padding-top: 20px;
    }

    .left {
        @include mobile-only {
            width: 40%;
        }
    }

    .right {
        @include mobile-only {
            width: 60%;
        }
    }
}
.text-content {
    a {
        &:hover {
            text-decoration: none;
        }

        &:focus {
            text-decoration: none;
        }
    }
}

.arb {
    .footer {
        .right {
            float: right;

            img {
                float: right;
                margin-right: 0;
                margin-left: 9px;
            }

            .inner-left {
                float: right;

                .address {
                    padding-right: 0;
                }
            }

            .inner-right {
                float: left;
            }

            @include mobile-only {
                padding-left: 0;
                padding-right: 5px;
                width: 60%;
            }
        }

        .left {
            float: right;

            @include mobile-only {
                width: 40%;

                &.left {
                    padding-right: 0;
                }
            }
        }

        .breadcrumbs {
            padding-right: 0;

            li {
                &:first-child {
                    &:after {
                        content: "";
                    }

                    &:before {
                        content: "";
                    }
                }

            }
        }

        .copyright {
            text-align: right;
        }
    }
}
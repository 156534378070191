.imf-protection {
    @include mobile-only {
        padding-left: 30px;
        padding-right: 30px;

        .container {
            padding: 0;
        }
    }

    h1 {
        font-size: 23px;

        @include tablet {
            font-size: 32px;
        }

        @include desktop-large {
            font-size: 41px;
        }
    }

    .content-left {
        @include tablet-only {
            width: 57%;
        }
    }

    .content-right {


        @include tablet-only {
            width: 43%;
            padding-left: 10px;
        }

        @include desktop {
            padding-left: 0;
        }

        @include desktop-large {
            padding-left: 25px;
        }
    }
}


.arb {
    .imf-protection {
        .content-left {
            float: right;
        }

        .content-right{
            float:right
        }
    }
}

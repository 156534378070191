
@mixin button-primary() {
	
}

@mixin column($n) {
	padding-left: 15px;
	padding-right: 15px;
	
	@include tablet {
		float: left;
		position: relative;
		width: (100%/$n);
	}
}

@mixin three-column {
	padding-left: 15px;
	padding-right: 15px;
	
	@include tablet {
		width: (100%/3);
		float: left;
		position: relative;
	}
}

@mixin flex {
	display: flex;
	
	&:before, &:after {
		content: none;
	}
}

@mixin full-width-mobile {
	@include mobile-only {
		margin-left: -15px;
		margin-right: -15px;
	}
}

@mixin global-border {
	
}

@mixin global-dropdown { // applied to .bootstrap-select
	min-width: 250px;
	
	&.open {
		
		.dropdown-toggle {
			
		}
	}
	
	.dropdown-toggle {
		
		
		&:after {
			display: block;
			content: '';
		}
		
		&:focus {
			outline: none !important; // vendor uses !important tag so this is the only way
			background-color: transparent;
		}
		
		&:hover {
			background-color: transparent;
		}
		
		&.btn-default {
			
			&:hover {
				background: transparent;
				border: 0;
			}
		}
	}
	
	> .dropdown-menu {
		margin: 0;
		border: 0;
		
		.inner {
			background-color: transparent;
		}
	}
}

@mixin global-form-label {

}

@mixin global-form-input {

}

@mixin global-h1 {
	@include MuseoSans700;
	color: $orange;
	font-size: 42px;
}

@mixin global-h2 {
    @include MuseoSans700;
    text-transform: uppercase;
    color: $orange;
    font-size: 32px;
}

@mixin global-h3 {
	@include MuseoSans700;
	color: $blue;
	font-size: 24px;
}

@mixin global-padding {
	padding: 20px;
	
	@include tablet {
		padding: 15px;
	}
	
	@include desktop {
		padding: 20px;
	}
}

@mixin global-shadow {
	box-shadow: 4px 6px 12px 0px rgba(0, 0, 0, 0.3)
}

@mixin transform($transform) {
	-o-transform: $transform;
	-moz-transform: $transform;
	-ms-transform: $transform;
	-webkit-transform: $transform;
	transform: $transform;
}

@mixin transition($transition...) {
	-o-transition: $transition;
	-moz-transition: $transition;
	-webkit-transition: $transition;
	transition: $transition;
}

@mixin transition-property($property...) {
	-o-transition-property: $property;
	-moz-transition-property: $property;
	-webkit-transition-property: $property;
	transition-property: $property;
}

@mixin transition-duration($duration) {
	-o-transition-duration: $duration;
	-moz-transition-duration: $duration;
	-webkit-transition-duration: $duration;
	transition-duration: $duration;
}

@mixin transition-timing-function($timing) {
	-o-transition-timing-function: $timing;
	-moz-transition-timing-function: $timing;
	-webkit-transition-timing-function: $timing;
	transition-timing-function: $timing;
}

@mixin transition-delay($delay) {
	-o-transition-delay: $delay;
	-moz-transition-delay: $delay;
	-webkit-transition-delay: $delay;
	transition-delay: $delay;
}

@mixin no-underline {
    &:hover {
        text-decoration: none;
    }
}

@mixin horizontal-row{
    height:1px;
    background-color:$alto;
    width:100%;
    display:block;
}

@mixin arrow-right($color) {
    width: 0px;
    height: 0px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid $color;
    display: inline-block;
    margin: 0 4px;
    
    @include desktop-large {
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid $color;
        margin: 0 20px;
    }
}
$mobile-width: 767px;
$tablet-width: 768px;
$desktop-width: 992px;
$desktop-large-width: 1200px;
$desktop-oversized-width: 1625px;

// Mobile-only and tablet-only mixins can be used to target the specific breakpoints and nothing else.
// All others will inherit styles on those breakpoints and above, so tablet will target the tablet breakpoint and everything larger than tablet.
// Since there is no real need to differentiate between desktop and desktop-large, there is no desktop-only mixin.

@mixin mobile-only {
    @media (max-width: #{$mobile-width}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin tablet-only {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin desktop-only {
    @media (min-width: #{$desktop-width}) and (max-width: #{$desktop-large-width}) {
        @content;
    }
}
@mixin desktop-large {
    @media (min-width: #{$desktop-large-width}) {
        @content;
    }
}

@mixin oversized { // For very wide desktop monitors, only to be used for edge cases dealing with the custom sizing of .container
    @media (min-width: #{$desktop-oversized-width}) {
        @content;
    }
}

@mixin ie11-only {
    @content;
}


@mixin portrait-view() {
    @media screen and (orientation: portrait){
        @content;
    }
}

@mixin landscape-view() {
    @media screen and (orientation: landscape) {
        @content;
    }
}

.container {
    @include tablet {
        margin-left: 20.6%;
    }

    @include tablet-only {
        @include portrait-view {
            margin-left: 16.5%;
        }
    }

    @include desktop-large {
        width: 987px;
    }
}

.main {
    .container {
        @include tablet-only {
            @include portrait-view {
                width: 73%;
                margin: 0 auto;
            }
        }

        @include tablet {
            @include landscape-view {
                width: 73%;
            }
        }

        @include desktop-large {
            width: 987px;
            margin-top: 30px;
        }

        @include desktop {
            @include landscape-view {
                margin-top: 20px;
            }
        }
    }

    .slider.container {
        @include desktop {
            @include landscape-view {
                margin-top: 0px;
            }
        }
    }
}


.arb {
    .container {
        margin-right: 20.6%;
        margin-left: 0;

        @include mobile-only {
            margin-right: 0;
        }
        
    }
}

.work-program {
    .subsection {display:block;
        .report-list {
            padding-top: 0;

            .report-inner {

                display:block;

                .title {
                    font-size: 16px;
                }
            }

            ul {
                li {
                    a{
                        display:block;
                    }
                    @include mobile-only {
                        min-height: 238px;

                        img {
                            max-width: inherit;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

// Common global values
$gutters: 15px;
$component-padding: 30px;
$container-offset: 200px;

$logo-width-tablet: 140px;
$logo-width-desktop: 200px;
$logo-width-desktop-large: 280px;

$header-height-tablet: 131px;;
$header-height-tablet-only: 90px;
$header-height-desktop: 102px;

$site-container-width-tablet: 750px - $logo-width-tablet - $gutters;
$site-container-width-desktop: 970px - $logo-width-desktop - $gutters;
$site-container-width-desktop-large: 1170px - $logo-width-desktop - $gutters;
$site-container-width-desktop-oversized: 1170px;

// Colors
$orange: #f57f29;
$blue: #084c77;
$green: #80BC41;
$white: #fff;
$black: #000;
// 50 Shades of Grey
$border-gray: #bfbfbf;
$search-gray: #ccc9c9; 
$light-gray: #c7c8c9;
$text-gray: #909090;
$dove: #707070;
$alto: #d1d1d1;
$zircon-gray: #e9ebeb;
// z-indexes
$z-index-header: 999;

// io
$img-path: '../../img/';

// transition easing
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$ease-in-out-quadratic: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quartic: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-out-quintic: cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-out-exponential: cubic-bezier(1, 0, 0, 1);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);
$ease-in-out-circular: cubic-bezier(0.785, 0.135, 0.15, 0.86);

$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-in-quadratic: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-quartic: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-in-quintic: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-exponential: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-in-circular: cubic-bezier(0.6, 0.04, 0.98, 0.335);

$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-out-quadratic: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-out-quintic: cubic-bezier(0.23, 1, 0.32, 1);
$ease-out-exponential: cubic-bezier(0.19, 1, 0.22, 1);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-out-circular: cubic-bezier(0.075, 0.82, 0.165, 1);

.document-viewer {

    ul {
        padding: 0;

        .doc-view-section {
            margin-bottom: 15px;
            list-style: none;
            border: none;
            box-shadow: none;


            .doc-view-inner {
                @include MuseoSans700;
                font-style: normal;
                color: white;
                padding: 38px 8px 10px 8px;
                display: block;
                cursor: pointer;
                text-decoration: none;
                font-size: 24px;
                font-weight:400;

                @include no-underline;

                &:hover {
                    @include no-underline;
                }

                &.collapsed {
                    img {
                        transform: rotate(0deg);
                        @include transition(transform .2s ease-in-out);
                    }
                }

                img {
                    vertical-align: middle;
                    margin-left: 10px;
                    height: 15px;
                    transform: rotate(90deg);
                    @include transition(transform .2s ease-in-out);
                }

                @include mobile-only {
                    font-size: 19px;
                    padding-top: 30px;
                }

                @include tablet-only {
                    font-size: 14px;
                    padding-top: 25px;
                }
            }

            > div {

                a {
                    display: block;
                    text-decoration: none;
                    font-size: 16px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:hover {

                        p {
                            text-decoration: underline;
                        }
                    }

                    p {
                        color: $blue;
                        padding-top: 20px;

                        @include desktop-large {
                            padding-top: 30px;
                        }
                    }

                    div {
                        padding-right: 0;
                        margin: 0;
                    }
                }

                .document-content {
                    ul {
                        li {
                            a {
                                border-right: none;
                            }
                        }
                    }
                }
            }


            .report-language {
                margin: 0 11px;
            }

            .document-content {
                align-items: center;
                display: flex;
                padding: 15px 0 0px 0;
            }

            .document-heading {
                color: $blue;
            }
        }
    }
}
.experience-editor {
    .document-viewer {
        ul {
            .doc-view-section {
                .scLooseFrameZone {
                    overflow: visible;
                }
            }
        }
    }
}
.arb {
    .doc-view-section {
        .doc-view-inner {


            &.collapsed {
                img {
                    transform: rotate(180deg) !important;
                    @include transition(transform .2s ease-in-out);
                }
            }

            img {
                vertical-align: middle;
                margin-right: 10px;
                height: 15px;
                transform: rotate(90deg) !important;
                @include transition(transform .2s ease-in-out);
            }
        }

        > div {
            a {
                .col-xs-3 {
                    float: right;
                }
            }
        }

        ul {
            &.report-language {
                margin: 0 16px;
            }
        }
    }
}

.report-list {
    margin-bottom: 5px;

    @include mobile-only {
        margin: 0 -5px;
        padding-top: 65px;
    }

    h3 {
        margin-bottom: 20px;
    }

    ul {
        list-style-type: none;
        padding: 0;
        justify-content: space-between;

        li {
            margin-bottom: 30px;
            padding: 0 11px;
            float: left;
            display: block;

            @include mobile-only {
                padding: 0 5px;
                margin-bottom: 10px;
                min-width: unset;
                width: 32%;
            }

            @include tablet {
                width: 183px;
                margin-bottom: 15px;
                padding-left: 0;
                min-height: 110px;
                min-width: initial;
            }

            @include desktop-large {
                width: 280px;
            }

            .report-inner {
                color: $black;

                @include mobile-only {
                    padding: 0;
                    font-size: 12px;
                    min-height: 210px;

                    img {
                        max-width: 70px;

                        @media (max-width: 1200px) and (min-width: 769px) {
                            max-width: 85px;
                        }
                    }
                }

                @include tablet {
                    min-height: 115px;
                    margin-bottom: 15px;
                }

                img {
                    float: left;
                }
            }



            .details {
                float: left;

                @include mobile-only {
                    margin-top: 5px;
                }

                @media (max-width: 1200px) and (min-width: 769px) {
                    width: calc(100% - 85px);

                    .evalution-report .report-list ul li .details {
                        width: 100%;
                    }
                }

                .date {
                    font-family: 'MuseoSans-700';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    text-transform: uppercase;
                    margin-bottom: 5px;

                    @include mobile-only {
                        font-size: 10px;
                    }
                }

                .title {
                    color: black;
                    font-family: "MuseoSans-300";
                    line-height: 1.25;

                    @include tablet {
                        font-size: 16px;
                    }

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .report-data {
        > a {
            display: block;
            margin-top: 10px;
            color: black;

            &:hover {
                text-decoration: none;
            }

            @include mobile-only {
                font-size: 12px;
            }
        }

        .report-language {
            margin: 5px -2px 0 -2px;

            .language {
                display: inline-block;
                padding-left: 3px;

                a {
                    display: block;
                    font-size: 16px;
                    padding-right: 5px;
                    line-height: 1;
                    border-right: 1px solid $orange;
                }

                &:last-child {
                    a {
                        border: none;
                    }
                }
            }
        }
    }

    ul {
        .vertical {
            .details {
                .language {
                    display: inline-block;

                    &:nth-child(2n) a {
                        padding-left: 5px;
                        border-left: 2px solid orange;
                    }

                    &:nth-child(2n+1) a {
                        border-right: none;
                    }
                }
            }
        }
    }
}

.evalution-report .report-list ul li .details {
    @media (max-width: 1200px) and (min-width: 769px) {
        width: 100%;
    }
}
.arb {
    .report-list {
        ul {
            li {
                float: right;

                &:first-child {
                    padding-right: 0;
                }

                .details {
                    padding-right: 0;
                    float: right;
                }

                img {
                    float: right;
                }

                .report-language {
                    padding-right: 10px;

                    .language {
                        &:last-child {
                            a {
                                border-right: 1px solid $orange;
                            }
                        }
                    }
                }
            }
        }
    }

    .report-language {

        .language {
            a {
                border-right: none;
                border-right: 1px solid $orange;
            }

            &:first-child {
                a {
                    border-right: none;
                }
            }

            &:last-child {
                a {
                    border-right: 1px solid $orange;
                }
            }
        }
    }
}

.hero-carousel {
    min-height: 80vh;
    width: 100%;
    padding: 0;
    overflow: hidden;
    margin-bottom: 0;
    margin-top: -100px;

    .slick-list {
        height: 100%;

        .slick-track {
            height: 100%;
        }
    }

    .slide {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        position: relative;
        height: 100%;
        min-height: 80vh;
    }

    .slick-arrow {
        position: absolute;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 10%;
        height: 45px;
        min-width: 4.2em;
        color: transparent;
        background: transparent;
        opacity: 0.8;
        z-index: 1000;

        @include mobile-only {
            height: 45px;
        }
    }

    .slick-prev {
        left: 100px;

        &:before {
            content: "";
        }

        &::after {
            -webkit-box-flex: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            background-color: #FFF;
            color: #111;
            font-family: 'Glyphicons Halflings';
            content: "\e257";
            font-size: 20px;
            font-weight: 700;
            line-height: 48px;
            text-indent: 12px;
        }

        &.pre-override {
            left: 0;

            &:after {
                content: "";
                width: 30px;
                height: 30px;
                background: url(/Assets/IEO/img/arrows.png);
            }
        }
    }

    .slick-next {
        &.next-override {
            right: 0;

            &:before {
                content: "";
                width: 30px;
                height: 30px;
                background: url(/Assets/IEO/img/arrows.png);
                background-position: 30px 0px;
            }
        }

        &::before {
            -webkit-box-flex: 0;
            -ms-flex-positive: 0;
            flex-grow: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            background-color: #FFF;
            color: #111;
            font-family: 'Glyphicons Halflings';
            content: "\e258";
            font-size: 20px;
            font-weight: 700;
            line-height: 48px;
            text-indent: 12px;
        }
    }

    .slide {
        .container {
            position: absolute;
            top: 25%;
            left: 0;
            right: 0;

            @include desktop {
                width: 987px;
            }

            .link {
                &:hover {
                    color: #f57f29;
                }
            }

            .read-more-arrow {
                width: 0px;
                height: 0px;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 5px solid #f57f29;
                display: inline-block;
                margin: 0 4px;

                @include desktop-large {
                    border-top: 7px solid transparent;
                    border-bottom: 7px solid transparent;
                    border-left: 7px solid #f57f29;
                    margin: 0 20px;
                }

                @include mobile-only {
                    border-top: 4px solid transparent;
                    border-bottom: 4px solid transparent;
                    border-left: 4px solid #f57f29;
                }
            }
        }

        .caption {
            width: 987px;

            @include tablet-only {
                width: 70%;
            }

            @include tablet {
                @include portrait-view {
                    width: 53%;
                }
            }

            @include desktop {
                width: 987px;
            }

            @include mobile-only {
                width: 60%;
            }

            h2 {
                font-family: MuseoSans-700;
                font-weight: 900;
                color: #084c77;
                font-size: 18px;
                margin: 0;
                text-transform: none;

                @include tablet {
                    font-size: 28px;
                }

                @include desktop {
                    font-size: 32px;
                    width: 50%;
                }

                @include desktop-large {
                    font-size: 41px;
                }
            }

            a, span {
                font-family: MuseoSans-700;
                font-style: normal;
                font-weight: 900;
                color: black;
                text-transform: uppercase;
                font-size: 10px;
                text-decoration: none;
                position: relative;

                &:hover {
                    text-decoration: none;
                }

                @include tablet {
                    font-size: 14px;
                }

                @include desktop {
                    font-size: 18px;
                }
            }

            a img {
                width: 8px;
                position: absolute;
                top: 50%;
                right: -12px;
                transform: translateY(-50%);
            }
        }
    }

    .slick-dots {
        bottom: 35px;

        li {
            border-radius: 30px;
            border: 1px solid white;
            height: 15px;
            width: 15px;
            border: 1px solid gray;

            button {
                height: 15px;
                width: 15px;

                &:before {
                    color: transparent;
                }
            }

            &.slick-active {
                background-color: white;
            }
        }
    }

    &.slick-dotted.slick-slider {
        margin-bottom: 0;
    }
}

.arb {
    .hero-carousel {
        .slick-track {
            -webkit-transform: translate3d(0,0,0) !important;
            -moz-transform: translate3d(0,0,0) !important;
            -ms-transform: translate3d(0,0,0) !important;
            -o-transform: translate3d(0,0,0) !important;
            -moz-transform: translate3d(0,0,0) !important;
            transform: translate3d(0,0,0) !important;
        }

        .slide .caption .link .read-more-arrow {
            @include transform(rotate(180deg));
        }
    }
}

.no-transition, .no-transition:after, .no-transition:before {
	-webkit-transition: none !important;
	-moz-transition: none !important;
	-o-transition: none !important;
	transition: none !important;
}

.remove-margin {
	margin: 0 !important;
}

.remove-margin-mobile {
	
	@include mobile-only {
		margin: 0 !important;
	}
}

.remove-bottom-margin-mobile {
	
	@include mobile-only {
		margin-bottom: 0 !important;
	}
}
.vacancies-page {
    h1 {
        font-size: 23px;

        @include tablet {
            font-size: 32px;
        }

        @include desktop-large {
            font-size: 41px;
        }
    }

    .accordion-container {
        h3 {
            font-size: 18px;

            @include tablet {
                font-size: 20px;
            }

            @include desktop-large {
                font-size: 24px;
            }
        }
    }

    .vacancy-card {
        li {
            color: black;
        }
    }
}


.accordion-container {
    .content-description {
        margin-bottom: 20px;
        color: black;
        word-wrap: break-word;
        font-family: "MuseoSans-500";

        ul {
            list-style: none;
            padding: 0;
            margin: 20px 0 0 0;

            li {
                padding-left: 1.2em;
                text-indent: -17px;
                display: list-item;
                font-family: "MuseoSans-500";

                &::before {
                    height: 5px;
                    width: 5px;
                    vertical-align: middle;
                    content: '';
                    border-radius: 15px;
                    margin-right: 12px;
                    background-color: #f57f29;
                    display: inline-block;
                }
            }
        }
    }
}

.arb {
    .vacancies-page {
       
    }
}

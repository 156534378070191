body {
    padding-top: 100px;
}
body.experience-editor {
    padding-top: 0;
}
@media screen and (min-width: 769px) and (orientation: portrait) {
    .footer .container {
        width: 78%;
    }
}

@media screen and (min-width: 769px) and (orientation: landscape) {
    .footer .container {
        width: 73%;
        padding: 0;
    }
}

@media (min-width: 1200px) {
    .footer .container {
        width: 85%;
        margin-left: 20.7%;
    }
}

.footer .breadcrumb-header .breadcrumbs li a {
    font-weight: 300;
}

.footer .breadcrumb-header {
    padding-left: 8px;
}

.arb .footer .breadcrumb-header {
    padding-left: 0;
}

.anchor-without-link {
    cursor: none;
}

.rss-feed {
    background-image: url(../../img/feed.png);
    position: absolute;
    right: 20px;
    display: inline-block;
    top: 20px;
    height: 16px;
    width: 16px;
}

@media (min-width: 1200px) {
    .container-fluid.whats-new-wrapper {
        padding-top: 30px;
    }

    .container-fluid.Generic-callout {
        padding-top: 30px;
    }
}

.report-list .report-data .completed-title {
    color: #f57f29;
}

@media (min-width: 769px) {
    .col-sm-7.col-md-8.content-left {
        width: 64%;
    }

    .col-sm-5.col-md-4.col-xs-12.content-right {
        width: 36%;
    }
}


.Generic-callout .generic-content, .Generic-callout .generic-content p, .Generic-callout .generic-content ul li {
    font-size: 14px;
}

    .Generic-callout .generic-content ul {
        list-style: none;
        padding: 0;
        margin: 20px 0;
    }

        .Generic-callout .generic-content ul li {
            padding-left: 2em;
            padding-left: 1.5em;
            text-indent: -17px;
            display: list-item;
        }

            .Generic-callout .generic-content ul li::before {
                height: 5px;
                width: 5px;
                vertical-align: middle;
                content: '';
                border-radius: 15px;
                margin-right: 12px;
                background-color: #f57f29;
                display: inline-block;
            }

.footer-home-icon {
    background-image: url(../../img/home-icon.png);
    height: 25px;
    width: 25px;
    display: inline-block;
    background-size: 25px;
}

.subsection .report-listing-icon {
    padding-left: 0;
}

@media (max-width: 768px) {
    .footer-home-icon {
        margin-left: -15px;
    }

    .arb .footer-home-icon {
        margin-right: -15px;
    }
}
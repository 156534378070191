.report-summary {
    padding: 0;

    .report-details {
        .row {

            a {
                color: $orange;
                margin-top: 6px;
                display: block;

                div {
                    /*padding-top: 6px;*/

                    h5 {
                        display: inline;
                    }

                    span {
                        float: right;
                    }
                }
            }

            .collect {

                @include tablet-only {
                    padding-right: 0;
                }

                @include desktop {
                    padding-right: 25px;
                }

                .recommend {
                    margin-bottom: 15px;
                    float: none;
                }

                .row {

                    h4 {
                        font-weight: bold;
                        font-size: 16px;
                    }

                    .vid {
                        margin-bottom: 15px;
                        float: none;

                        .bc-player-HyjoUEN3_default {
                            width: 100%;
                        }


                        iframe {
                            @include tablet {
                                min-height: 157px;
                            }

                            @include tablet-only {
                                min-height: 120px;
                            }
                        }
                    }
                }
            }

            .icon-download {
                background-image: url($img-path + "icon-download.png");
                background-repeat: no-repeat;
                background-size: 23px;
                width: 23px;
                height: 23px;
                display: inline-block;
                float: right;
                background-position: right center;
            }
        }
    }

    .lang-links {
        padding-top: 40px;
        padding-right: 0;
        margin-bottom: 20px;

        @include mobile-only {
            padding-top: 0;
            margin-bottom: 40px;
            margin-top: 20px;

            div {
                padding: 0;

                a {
                    padding: 0;
                    font-size: 12px;
                }
            }
        }

        ul {
            padding: 0;

            li {
                list-style: none;
                display: inline-block;
                line-height: 1;

                @include mobile-only {
                    padding-right: 5px;
                }

                &:nth-child(2n) {

                    @include mobile-only {
                        padding: 0;
                    }
                }

                &:nth-child(2n+1) {
                    a {
                        @include mobile-only {
                            border: none;
                            padding: 0;
                        }
                    }
                }

                &:last-child {
                    a {
                        @include desktop {
                            border: none;
                        }
                    }
                }

                &:first-child a {
                    padding-left: 3px;
                }

                a {
                    display: inline-block;
                    border-right: solid 2px #f57f29;
                    padding: 0 8px 0 5px;

                    @include mobile-only {
                        margin-bottom: 3px;
                        padding-left: 10px;
                        border-right: none;
                        border-left: solid 2px #f57f29;
                    }
                }
            }
        }

        h4 {
            font-size: 14px;
            font-weight: bold;

            @include tablet {
                font-size: 16px;
            }
        }

        > div {
            padding: 0;

            &.col-sm-9 {
                @include mobile-only {
                    width: 137px;
                }
            }
        }

        @media only screen and (max-width: 320px) {
            width: 59.33%;
        }
    }
}
.doc-view-section .report-language {
    padding-left: 31.5%;
}
.document-viewer .report-language .language a {
    display: inline-block;
    border-right: solid 2px #f57f29;
    padding: 0 8px 0 3px;
}

.document-viewer .report-language .language:last-child a {
    border-right: none;
}
.report-language {
    padding: 0;
    list-style: none;
    margin: 0 -5px;

    li {
        display: inline-block;

        &:nth-child(2n) {
            a {
                @include tablet-only {
                    border: none;
                }
            }
        }

        &:last-child {
            a {
                @include desktop {
                    border: none;
                }
            }
        }

        &:first-child {
            a {
                border-left: none;
            }
        }

        a {
            line-height: 1;
            display: inline-block;
            border-right: solid 2px #f57f29;
            font-size: 16px;

            @include mobile-only {
                margin-bottom: 3px;
            }
        }
    }
}
.report-details {
    .report-language {
        padding-left: 5px;

        li {
            padding-left: 1px;
        }
    }
}
.content-left .report-details .report-language li {
    a {
        padding-right: 6px;

        @include mobile-only {
            padding-left: 6px;
            border-right: none;
            border-left: solid 2px #f57f29;
        }
    }

    &:nth-child(2n+1) a {
        @include mobile-only {
            border: none;
            padding: 0;
        }
    }
}

.arb {
    .report-details {
        .icon-download {
            float: left !important;
        }

        @include mobile-only {
            .row {
                > .col-xs-6 {
                    float: right;
                }
            }
        }

        .row {
            > .col-xs-6 {
                float: right;
            }
        }

        .collect {
            padding-right: 0 !important;
        }
    }

    .lang-links {
        float: right;
        padding-right: 15px;

        ul {
            li {
                a {
                    display: inline-block;
                    padding: 0 7px 0 5px;

                    @include mobile-only {
                        margin-bottom: 3px;
                    }
                }


                &:nth-child(2n+1) {
                    @include mobile-only {
                        a {
                            border-right: none !important;
                        }
                    }
                }

                &:nth-child(2n) {
                    a {
                        border-right: 2px solid $orange !important;
                        border-left: none;
                    }
                }

                &:first-child {
                    a {
                        @include tablet {
                            border: none;
                        }
                    }
                }
            }
        }

        @include mobile-only {
            padding-right: 15px;
        }

        > .col-xs-12 {
            float: right;
        }

        @media only screen and (max-width: 320px) {
            padding-left: 28px;
        }
    }
}

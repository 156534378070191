.site-search {
    .hr-line {
        @include horizontal-row;
    }

    .site-search-inner {
        .search-top-text {
            font-size: 16px;
            margin-top: 10px;
            @include MuseoSans500;

            @include mobile-only {
                font-size: 13px;
            }

            a {
                @include MuseoSans900;
                color: $blue;

                &:hover {
                    cursor: auto;
                    text-decoration: none;
                }
            }
        }

        .search-content {
            margin-top: 10px;

            .search-bar {
                input {
                    width: 46%;
                    float: left;
                    padding-left: 8px;
                    border: 1px solid $search-gray;

                    @include mobile-only {
                        width: 85%;
                    }
                }

                a {
                    background-image: url("../../assets/img/icon-magnify.png");
                    background-position: right 5px center;
                    background-repeat: no-repeat;
                    height: 29px;
                    width: 32px;
                    display: block;
                    float: left;
                    margin-left: 10px;
                }
            }

            .sort-results {
                a {
                    @include MuseoSans700;
                    @include no-underline;
                    font-size: 20px;
                    color: $orange;

                    span {
                        vertical-align: middle;
                        @include arrow-right($blue);

                        @include tablet {
                            margin: 0 0 0 10px;
                        }
                    }
                }
            }

            .radio-choice {
                display: block;
                margin-bottom: 10px;
            }

            .radio-btn {
                display: inline-block;

                @include tablet {
                    float: left;
                }

                @include mobile-only {
                    margin-top: 5px;
                }

                &:first-child {
                    @include tablet {
                        margin-right: 50px;
                    }
                }

                &:last-child {
                    @include mobile-only {
                        float: right;
                    }
                }

                label {
                    @include mobile-only {
                        font-size: 14px;
                    }
                }

                input {
                    outline: none;
                }
            }

            .search-results {
                .search-result-item {
                    margin: 15px 0 25px 0;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                .result-title {
                    color: $orange;
                    font-size: 18px;
                    @include MuseoSans500;

                    @include mobile-only {
                        font-size: 15px;
                    }
                }

                p {
                    font-size: 16px;
                    margin-bottom: 0;

                    @include mobile-only {
                        font-size: 13px;
                        margin-bottom: 7px;
                    }

                    &.result-date {
                        color: $blue;
                        @include MuseoSans500;
                    }

                    &.result-description {
                        margin-bottom: 10px;
                        color: $black;
                        @include MuseoSans700;
                    }
                }

                .result-link {
                    color: $orange;
                    @include MuseoSans500;
                    @include no-underline;
                    word-wrap: break-word;
                    line-height: 1;
                    font-size: 14px;

                    @include mobile-only {
                        font-size: 13px;
                    }

                    span {
                        @include MuseoSans900;
                        color: $blue;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .pagination {
        width: 100%;

        .pagination-content {
            height: 40px;
            padding: 8px 0;
            position: relative;

            .page-counter {
                font-size: 16px;
                @include MuseoSans700;

                @include mobile-only {
                    font-size: 13px;
                    float: left;
                    padding-top: 1px;
                }
            }

            .page-numbers {
                color: $orange;
                text-align: center;
                display: inline-block;
                position: absolute;
                right: 0;
                left: 0;
                top: 2px;

                @include mobile-only {
                    position: relative;
                    float: right;
                    margin-top: -6px;
                }

                .search-arrow-left {
                    vertical-align: middle;
                    transform: rotate(180deg);
                    @include arrow-right($blue);
                }

                .search-arrow-right {
                    vertical-align: middle;
                    @include arrow-right($blue);
                }

                .page-values {
                    display: inline-block;
                    vertical-align: middle;

                    a {
                        color: $orange;
                        @include MuseoSans700;
                        @include no-underline;
                        padding: 0 2px;

                        @include mobile-only {
                            font-size: 13px;
                        }


                        &.active {
                            color: $blue;
                            font-size: 22px;
                            @include MuseoSans900;

                            @include mobile-only {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.arb {
    .site-search {
        .search-bar {
            input {
                float: none !important;
                margin-right:0;
            }

            a {
                float: right !important;
            }
        }

        .radio-choice {
            .radio-btn {

                @include tablet {
                    float: right;
                    margin-right: 0 !important;
                    margin-left: 50px;

                    &:last-child {
                        margin-left: 0;
                    }
                }

                @include mobile-only {
                    &:last-child {
                        float: left;
                    }
                }
            }
        }

        .page-numbers {
            .search-arrow-left {
                transform: rotate(360deg) !important;
            }

            .search-arrow-right {
                transform: rotate(180deg);
            }
        }

        .pagination-content {
            .page-counter {

                @include mobile-only {
                    float: right;
                }
            }

            .page-numbers {
                @include mobile-only {
                    float: left;
                }
            }
        }

        .sort-results {
            span {
                transform: rotate(180deg) !important;
            }
        }
    }
}

.evalution-report {
    h1 {
        font-size: 23px;

        @include tablet {
            font-size: 32px;
        }

        @include desktop-large {
            font-size: 41px;
        }
    }

    .accordion-container {
        h3 {
            font-size: 18px;

            @include tablet {
                font-size: 20px;
            }

            @include desktop-large {
                font-size: 24px;
            }
        }
    }

    .subsection {
        .report-list {
            padding-top: 0;
            margin-right: -12px;

            @include mobile-only {
                margin-right: -5px;
            }

            .report-inner {

                img {
                    max-width: inherit;
                    width: 100%;
                }

                .title {
                    font-size: 16px;
                }
            }

            ul {
                li {

                    @include mobile-only {
                        min-height: 238px;
                    }
                }
            }
        }
    }

    .eval-reports {
    }
}


.arb {
    .evalution-report {
        .subsection {
            .report-list {
                margin-right: -12px;

                li{
                    &:first-child{
                        padding-right:12px;
                    }

                    @include mobile-only{
                        &:last-child{
                            padding-right:12px;
                        }
                    }
                }
            }
        }
    }
}
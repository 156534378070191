.cta-list {
    list-style-type: none;
    @include flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 54px -10px 25px -10px;

    @include mobile-only {
        margin: 0 -5px 20px -5px;
    }

    .cta-without-img {
        color: black;
        height: 48px;
        width: 178px;
        background-color: gray;
        text-align: center;
        padding: 13px;

        @include mobile-only {
            height: 36px;
            width: 48%;
            padding: 7px;
        }

        &:hover {
            text-decoration: none;
            color: black;
        }
    }

    &:before, &:after {
        content: none;
    }

    &.cta-orange {
        a {
            margin-top: 40px;

            @include desktop-large {
                margin-top: 150px;
            }
        }
    }

    .cta-link {
        @include tablet-only {
            margin-bottom: 15px;
        }
    }

    &.cta-gray {
        margin-top: 100px;

        @include mobile-only {
            margin-top: 0;
        }
    }

    .cta-list-inner {
        margin: 0 10px;
        width: 175px;
        cursor: pointer;
        display: block;
        padding: 7px;
        color: white;
        position: relative;
        min-height: 111px;

        .scWebEditInput {
            display: inline;
        }

        @include mobile-only {
            margin: 7px;
            min-height: 80px;
            width: 92px;
            padding: 5px;
            margin-bottom: 10px;
        }

        @include tablet {
            min-height: 154px;
            margin-bottom: 15px;
        }

        @include tablet-only {
            width: 140px;
            min-height: 125px;
            margin: 0 7px;
        }

        .title {
            @include MuseoSans700;
            color: white;
            font-size: 24px;
            line-height: 1.2;
            position: relative;
            font-weight: 300;

            @include mobile-only {
                font-size: 12px;
            }

            @include tablet-only {
                font-size: 14px;
            }
        }

        svg, .icon {
            position: absolute;
            left: 15px;
            bottom: 15px;
            fill: white;
            max-width: 50px;
            max-height: 50px;

            @include mobile-only {
                height: 25px;
                width: 25px;
                left: 7px;
                bottom: 7px;
                background-size: contain;
            }
        }
    }

    a {
        &:hover {
            text-decoration: none;

            .title {
                text-decoration: none;
            }
        }
    }

    .arrow-right {
        height: 14px;
        width: 10px;
        float: right;
        background-image: url("../../img/icon-angle-bracket-right.png");
        background-repeat: no-repeat;
        background-position: right;
        margin-top: 7px;

        @include mobile-only {
            height: 10px;
            width: 9px;
            margin-top: 1px;
            background-size: contain;
            margin-top: 1px;
        }
    }

    .cta-list-heading {
        display: block;
        width: 100%;
        margin-bottom: 22px;
        padding: 0 10px;

        @include mobile-only {
            margin-bottom: 14px;
            font-size: 14px;
        }
    }

    .cta-img {
        display: inline-block;
        margin-bottom: 15px;
        width: 23%;

        @include tablet {
            min-height: 154px;
            margin-bottom: 15px;

            &:last-child {
                margin-right: 0;
            }
        }

        @include mobile-only {
            width: 47%;

            &:nth-child(2n) {
                margin-right: 12px;
            }
        }
    }
}
.experience-editor {
    .cta-list {
        .cta-without-img {
            .scEmptyImage {
                display: none;
            }
        }

        .cta-list-inner {
            img {
                position: absolute;
                left: 15px;
                bottom: 15px;
                fill: white;
                max-width: 50px;
                max-height: 50px;
            }
        }
    }
}

.arb {
    .arrow-right {
        @include transform(rotate(180deg));
        float: left;
    }
}

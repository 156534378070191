.accordion-container {
    border-bottom: solid 1px $alto;
    margin-bottom: 30px;


    .panel {
        overflow: hidden;
        position: relative;
        margin-bottom: 15px;
        font-weight: bolder;
        transition: max-height 0.2s ease-in-out;
        box-shadow: none;
    }


    .expanded:after {
        display: none;
    }

    .more-btn {
        font-family: "MuseoSans-700";
        font-style: normal;
        font-weight: 900;
        color: $orange;
        margin-bottom: 5px;
        font-size: 16px;

        @include mobile-only {
            font-size: 16px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .less-btn {
        font-family: "MuseoSans-700";
        font-style: normal;
        font-weight: 900;
        display: none;
        color: $orange;
        margin-bottom: 5px;
        margin-top: 10px;
        font-size: 16px;

        &:hover {
            cursor: pointer;
        }

        img {
            transform: rotate(180deg);
        }
    }
}

.evaluator-list {
    padding-top: 12px;

    h3 {
        color: black;
        margin-bottom: 14px;
        font-size: 14px;

        @include tablet {
            font-size: 18px;
            margin-bottom: 22px;
        }

        @include desktop-large {
            font-size: 24px;
        }
    }

    a {
        display: inline-block;
        margin: 0 15px 15px 0;
        
        @include mobile-only {
            width: 48%;

            &:nth-child(2n+1) {
                margin-right: 0;
            }
        }
    }
}

.whats-new-wrapper {

    h1 {
        font-size: 23px;

        @include tablet {
            font-size: 32px;
        }

        @include desktop-large {
            font-size: 41px;
        }
    }

    .accordion-container {
        .panel {
            @include mobile-only {
                margin-bottom: 0;
            }
        }

        &:last-child {
            margin-bottom: 100px;
        }
    }

    .whats-new-card {
        margin-bottom: 20px;

        h3 {
            font-size: 18px;

            @include tablet {
                font-size: 20px;
            }

            @include desktop-large {
                font-size: 24px;
            }
        }

        h4 {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 5px;

            @include tablet {
                font-size: 18px;
            }
        }

        .news-data {
            font-size: 13px;
            font-weight: 600;
            margin: 0 0 20px;

            @include tablet {
                font-size: 14px;
            }
        }
    }
}

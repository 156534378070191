.tor-page {

    h1 {
        font-size: 23px;

        @include tablet {
            font-size: 32px;
        }

        @include desktop-large {
            font-size: 41px;
        }
    }

    .accordion-container {
       
        h4 {
            font-weight: 600;
            font-size: 16px;
            margin-bottom: 5px;
            @include MuseoSans700;

            @include tablet {
                font-size: 18px;
            }
        }
    }

    .terms-of-ref {

        .ref-card {
            padding-bottom: 30px;

            p {
                font-size: 14px;
            }
        }

        .content-subheading {
        }
    }

    .content-inner{
        margin-bottom:20px;
    }
}

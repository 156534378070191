.breadcrumb-header {
    margin-bottom: 25px;

    & .breadcrumbs > li:nth-of-type(1) > a {
    }

    & .breadcrumbs > li:nth-of-type(1) {
    }
}

.breadcrumbs {
    padding-left: 15px;

    @include tablet {
        padding-left: 0;
    }

    li {
        list-style-type: none;
        display: inline;
        padding: 0 10px 0 0;
        position: relative;
        font-size: 14px;
        margin-right: 4px;

        &:first-child {
            @include mobile-only {
                padding: 0;
                margin: 0;
            }

            a {
                &:before {
                    content: none;
                }
            }
        }

        &:last-child {
            font-weight: bold;
            padding: 0;

            a {
                cursor: auto;

                &:hover {
                    @include no-underline;
                }
            }
        }

        a {
            color: $blue;

            &:not([href]) {
                &:not([class]) {
                    color: $blue;

                    &:hover {
                        color: $blue;
                    }
                }
            }

            &:before {
                content: ">";
                position: absolute;
                left: -15px;
                transform: translateX(50%);
                top: -2px;
            }
        }
    }
}

.arb {
    .breadcrumb-header {
        .breadcrumbs {
            > li {

                &:last-child:before {
                    content: ">";
                }

                &:nth-of-type(1) {
                    > a {
                        visibility: visible;
                    }
                }
            }
        }
    }
}

$arrow-padding: 15px;

.news-slider {
    background-color: $orange;
    color: white;
    padding: 10px 0;
    position: relative;
    min-height: 90px;

    @include mobile-only {
        min-height: 82px;
    }

    .headline {
        @include MuseoSans700;
        color: black;
        font-size: 36px;
        padding: 10px 25px;
        width: 20%;
        float: left;
        line-height: 1.2;
        font-weight: 300;

        @media (max-width: 450px) {
            margin-top: 4%;
        }

        @include mobile-only {
            padding-left: $arrow-padding;
            display: block;
            padding: 0 0 0 10px;
            margin-top: 1%;
            font-size: 20px;
        }

        @include tablet-only {
            font-size: 22px;
            padding: 10px 0 0 10px;
            margin-top: 10px;
        }

        @include tablet {
            width: 18%;
            padding: 10px 15px;
            font-size: 27px;
            margin-top: 17px;

            @include landscape-view {
                width: 18%;
                padding: 10px 15px;
            }
        }

        @include desktop-large {
            width: 20%;
            margin-top: 20px;
            font-size: 36px;
            padding-left: 20px;
        }
    }

    .slider {
        padding: 0 $arrow-padding;
        float: left;
        margin-left: 4.1%;
        padding: 10px 15px;
        position: relative;
        width: 55%;

        @include mobile-only {
            padding: 0;
            margin-left: 8%;
            width: 60%;
        }

        @include tablet-only {
            padding-top: 0;
            padding-bottom: 0;
            width: 59%;
        }

        @include tablet {
            @include landscape-view {
                margin-left: 4%;
            }
        }

        @include desktop-large {
            margin-left: 4.1%;
        }

        @media only screen and (max-width: 320px) {
            width: 60%;
            margin-left: 10%;
        }

        .idangerous-slider {
            overflow: hidden;

            .swiper-wrapper {
                @include flex;
                align-items: center;

                .swiper-slide {

                    & > div {
                        font-size: 20px;

                        @include mobile-only {
                            font-size: 13px;
                        }

                        @include tablet-only {
                            font-size: 16px;
                        }
                    }

                    .date {
                        @include MuseoSans700;
                        color: black;
                        font-weight: 700;
                    }

                    .caption {
                        @include MuseoSans500;
                        font-size: 13px;
                        color: black;

                        @include tablet {
                            font-size: 14px;
                        }

                        @include desktop {
                            font-size: 16px;
                        }

                        @include desktop-large {
                            font-size: 20px;
                        }

                        a {
                            color: white;

                            &:hover {
                                text-decoration: none;
                            }
                        }

                        &:hover {
                            cursor: pointer;
                            @include no-underline;
                        }
                    }
                }
            }
        }

        .swiper-button-prev, .swiper-button-next {


            &.swiper-button-prev {
                width: 0px;
                height: 0px;
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
                border-right: 20px solid white;
                display: inline-block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                background-image: none;
                left: -25px;

                @include mobile-only {
                    border-top: 14px solid transparent;
                    border-bottom: 14px solid transparent;
                    border-right: 13px solid white;
                }
            }

            &.swiper-button-next {
                width: 0px;
                height: 0px;
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
                border-left: 20px solid white;
                display: inline-block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                background-image: none;
                right: -25px;

                @include mobile-only {
                    border-top: 14px solid transparent;
                    border-bottom: 14px solid transparent;
                    border-left: 13px solid white;
                }
            }
        }
    }
}

.arb {
    .slider {
        float: right;
        margin-right: 4.1%;


        @include mobile-only {
            margin-right: 8%;
        }

        @include tablet {
            @include landscape-view {
                margin-right: 1.4%;
            }
        }

        @include desktop-large {
            margin-right: 4.1%;
        }

        @media only screen and (max-width: 320px) {
            margin-right: 10%;
        }
    }

    .headline {
        float: right;

        @include mobile-only {
            padding-right: 15px;
        }

        @media screen and (max-width: 320px) {
            font-size: 17px;
        }

        @include tablet {
            padding-right: 60px;
        }
    }
}

.team-bio {
    padding: 40px 0 40px 0;

    @include mobile-only {
        padding-top: 0px;
        background-size: auto;
    }

    @include tablet-only {
        padding-top: 45px;
    }

    h3 {
        margin-bottom: 12px;
        font-size: 18px;
        font-family: MuseoSans-500;
        font-weight: 700;

        @include tablet {
            font-size: 24px;
            margin-bottom: 16px;
        }

        @include desktop-large {
            font-size: 32px;
            margin-bottom: 24px;
        }
    }

    .team-description {
        @include tablet {
            width: 40%;
        }

        @include desktop {
            padding-left: 40px;
            width: 30%;
        }

        @include mobile-only {
            margin-top: 10px;
            font-family: MuseoSans-300;
        }

        p {
            font-size: 18px;

            @include tablet-only {
                font-size: 14px;
            }

            @include mobile-only {
                margin-top: 10px;
                font-family: MuseoSans-300;
            }
        }
    }
}

.team-members {

    .team-grid {
        display: block;
        justify-content: space-between;
        margin-bottom: 30px;

        &.small-grid {
            margin: 0 -10px;
            margin-bottom: 20px;

            .bio {
                cursor: pointer;
                float: left;
                margin-bottom: 30px;
                min-height: 246px;
                padding: 0 10px;

                @include mobile-only {
                    margin-bottom: 25px;
                    min-height: 140px;
                }

                &:nth-child(5n) {
                    margin-right: 0;
                }

                .member-inner {
                    width: 162px;

                    @include mobile-only {
                        width: 83px;
                        min-height: 160px;
                    }
                }

                &:hover {
                    .name {
                        text-decoration: underline;
                    }

                    .position {
                        text-decoration: underline;
                    }
                }

                .name {
                    font-size: 18px;

                    @include mobile-only {
                        font-size: 13px;
                    }

                    @include tablet-only {
                        font-size: 14px;
                    }
                }

                .position {
                    font-size: 15px;
                    line-height: 1;

                    @include mobile-only {
                        font-size: 11px;
                    }

                    @include tablet-only {
                        font-size: 12px;
                    }
                }
            }
        }

        &.large-grid {
            margin: 0 -12px;
            margin-bottom: 40px;

            @include mobile-only {
                margin-bottom: 20px;
            }

            .bio {
                cursor: pointer;
                float: left;
                margin-bottom: 20px;
                padding: 0 12px;

                img {
                    @include mobile-only {
                        min-height: 128px;
                    }
                }

                .name {
                    font-size: 21px;

                    @include mobile-only {
                        font-size: 13px;
                    }

                    @include tablet-only {
                        font-size: 16px;
                    }
                }

                .position {
                    font-size: 18px;

                    @include mobile-only {
                        font-size: 11px;
                    }

                    @include tablet-only {
                        font-size: 14px;
                    }
                }

                .member-inner {
                    @include mobile-only {
                        width: 112px;
                        width: 112px;
                        min-height: 160px;
                    }

                    @include tablet {
                        width: 221px;
                    }

                    @include tablet-only {
                        width: 165px;
                    }
                }

                &:hover {
                    .name {
                        text-decoration: underline;
                    }

                    .position {
                        text-decoration: underline;
                    }
                }
            }
        }

        .name {
            @include MuseoSans500;
            color: $orange;
            font-weight: 700;
        }

        .position {
            @include MuseoSans500;
            color: #595959;
            font-weight: 700;
        }

        img {
            margin-bottom: 10px;

            @include mobile-only {
                min-height: 96px;
            }
        }
    }


    .team-highlight {
        top: 5%;
        right: 0;
        bottom: auto;
        display: block !important;

        @include mobile-only {
            top: 0;
            left: 0;
            height: 100%;
            overflow-y: auto;
            padding-right: 20px;
        }

        .modal-dialog {
            width: 47%;
            max-width: unset;

            @include mobile-only {
                width: 100%;
            }

            @include tablet-only {
                width: 85%;
                margin: 10px auto;
            }

            .modal-header {
                border: none;
                padding: 0;
                height: 30px;
                display: block;

                .close {
                    float: left;
                    padding: 10px;
                    opacity: 1;

                    span {
                        color: $orange;
                        font-size: 30px;
                    }
                }
            }

            .modal-body {
                padding: 0 30px 30px 40px;
                margin: 0;

                .arrow-right {
                    position: absolute;
                    width: 50px;
                    top: 30px;
                    left: 0;
                    width: 0px;
                    height: 0px;
                    border-top: 120px solid transparent;
                    border-bottom: 120px solid transparent;
                    border-left: 120px solid $orange;
                    z-index: 1;

                    @include mobile-only {
                        top: 130px;
                        border-top: 85px solid transparent;
                        border-bottom: 85px solid transparent;
                        border-left: 85px solid #f57f29;
                    }
                }

                .person-bio {
                    img {
                        width: 100%;
                        margin-right: 15px;
                        display: inline-block;
                        float: left;
                        padding-bottom: 20px;

                        @include mobile-only {
                            width: 100%;
                        }
                    }

                    .name-mobile {
                        width: 56%;
                        display: none;

                        @include mobile-only {
                            width: 100%;
                            display: inline-block;
                        }

                        h1 {
                            margin-bottom: 15px;

                            @include tablet-only {
                                max-width: none;
                            }
                        }

                        h3 {
                            color: #707070;
                            font-weight: 500;
                            margin-bottom: 15px;
                        }
                    }

                    .name-desktop {
                        padding-left: 8px;
                        width: 40%;
                        display: inline-block;

                        @include mobile-only {
                            display: none;
                        }

                        h1 {
                            margin-bottom: 15px;
                        }

                        h3 {
                            color: #707070;
                            font-weight: 500;
                            margin-bottom: 15px;
                        }
                    }

                    .about {
                        padding-left: 0;
                        padding-right: 8px;

                        @include desktop {
                            line-height: 1.5;
                            padding: 0 0 0 10px;
                            width: 45%;
                            float: left;
                        }
                    }

                    .name {
                        max-width: 300px;

                        @include tablet-only {
                            max-width: auto;
                        }
                    }

                    .bio-img {
                        padding: 0;

                        @include mobile-only {
                            width: 100%;
                        }

                        @include tablet-only {
                            padding: 0 15px;
                            width: 50%;
                        }

                        @include desktop-large {
                            width: 54%;
                        }
                    }
                }
            }
        }
    }

    .modal.fade:not(.in).right .modal-dialog {
        -webkit-transform: translate3d(125%, 0, 0);
        transform: translate3d(125%, 0, 0);
    }
}
.team-intro {
    margin-bottom: 40px;

    .caption {
        @include tablet-only {
            padding: 0;
        }
    }

    img {
        @include mobile-only {
            margin-bottom: 10px;
        }
    }
}

.experience-editor .team-members .team-grid .scLooseFrameZone {
    display: inline-block;
}

.experience-editor .team-members .team-grid.large-grid .bio .member-inner {
    width: 217px;
}

.arb {

    .subsection ul li {
        @include mobile-only {
            margin-left: 5%;
        }
    }

    .evalution-report .subsection .report-list ul li:nth-child(2n) {
        @include mobile-only {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .team-members .team-highlight .modal-dialog .modal-body .person-bio .name-desktop {
        padding-left: 0;
        padding-right: 8px;
    }

    .team-members .team-highlight .modal-dialog .modal-body .person-bio .about {
        padding-left: 0;
        padding-right: 8px;
    }

    .team-bio {
        .team-img {
            float: right;
        }
    }

    .team-intro {
        > div {
            float: right;
        }
    }

    .team-members .team-highlight .modal-dialog .modal-body .person-bio .bio-img {
        float: right;
    }

    .team-highlight {

        @include mobile-only {
            width: 100%;
            padding-left: 20px !important;
        }

        .modal-dialog {
            .modal-header {
                height: 38px;

                .close {
                    float: right;
                    padding: 5px 10px;
                }
            }

            .modal-body {

                .arrow-right {
                    right: 0;
                    transform: rotate(180deg);
                }

                .bio {

                    img {
                        float: right;
                        margin-right: 0;
                        margin-left: 15px;
                    }

                    .about {
                        @include desktop {
                            width: 55.5%;
                        }
                    }

                    > div {
                        float: left;
                    }
                }
            }
        }
    }

    .large-grid {
        .bio {
            float: right !important;
        }
    }

    .small-grid {
        .bio {
            float: right !important;
        }
    }
}

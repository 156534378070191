// This project architected using Inverted Triangle CSS: https://www.xfive.co/blog/itcss-scalable-maintainable-css-architecture/

// ITCSS:
//		Settings: vars.scss, fonts.scss
//		Tools: responsive.scss, mixins.scss
//		Generic: N/A (reset included in BootStrap)
//		Elements: global.scss
//		Objects: global.scss
//		Components: all scss files under components/*.scss
//		Utilties: helpers.scss

// Vendors
/* Need to run 'build-bootstrap' task to get this bootstrap.min.scss*/
@import '../../vendors/css/bootstrap.min.scss';
@import '../../vendors/css/swiper.min.scss';
@import '../../vendors/css/bootstrap-select.min.scss';

// Custom
@import 'vars';
@import 'fonts';
@import 'responsive';
@import 'mixins';
@import 'global';

@import 'components/accordion-container';
@import 'components/breadcrumbs';
@import 'components/callout-listing';
@import 'components/cta-list';
@import 'components/document-viewer';
@import 'components/evaluation-reports';
@import 'components/evaluator-list';
@import 'components/footer';
@import 'components/header';
@import 'components/hero-carousel';
@import 'components/news-slider';
@import 'components/report-list';
@import 'components/report-summary';
@import 'components/subsection';
@import 'components/team-bio';
@import 'components/terms-of-reference';
@import 'components/vacancies';
@import 'components/whats-new';
@import 'components/site-search';
@import 'components/imf-protection';
@import 'components/work-program';

@import 'helpers';

@import 'components/misc-components'

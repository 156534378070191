@import 'vars.scss';
body {
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	font-size: 14px;
	line-height: 1.42857143;
	color: #333;
	background-color: #fff;
}
body {
	@include MuseoSans300;
	font-size: 16px;
	margin: 0;
	overflow-x: hidden;
}

a {
	word-break: break-word;
	color: $orange;
	text-decoration: none;

	&:hover {
		cursor: pointer;
		color: $orange;
	}

	&:active {
		text-decoration: none;
	}
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
}

h1 {
	font-family: "MuseoSans-700";
	font-style: normal;
	color: #f57f29;
	font-size: 42px;
}

h2 {
	font-family: "MuseoSans-700";
	font-style: normal;
	text-transform: uppercase;
	color: #f57f29;
	font-size: 32px;
}

h3 {
	font-family: "MuseoSans-700";
	font-style: normal;
	color: #084c77;
	font-size: 24px;
}

img, iframe, video {
	max-width: 100%;
}

p, ul, ol, p > ul, p > ol {
	@include MuseoSans300;
	font-size: 16px;
	margin-bottom: 20px;
	font-weight: 400;
	
	&:last-child {
		margin-bottom: 0;
	}
}

.background-color-blue {
	background-color: $blue;
}

.background-color-dove {
	background-color: $dove;
}

.background-color-orange {
	background-color: $orange;
}

.background-color-green {
	background-color: $green;
}

.container-fluid {
	.container {
		max-width: 100%;
		position: relative;

		h2 {
			@include tablet-only {
				font-size: 24px;
			}
		}
	}
}

.container {
	&:after {
		clear: both; 
		display: table;
		content: " ";
	}

	@include tablet {
		width: $site-container-width-tablet;
	}

	@include desktop {
		width: $site-container-width-desktop;
	}

	@include desktop-large {
		width: $site-container-width-desktop-large;
	}

	@include oversized {
		width: $site-container-width-desktop-oversized;
	}

	@include desktop-only {
		width: $site-container-width-desktop;
	}
}
.footer .container {
	@include desktop-only {
		width: 82%;
	}
}
.generic-content, .generic-content p, .generic-content ul, .team-description p {
	font-size: 14px;
	font-family: MuseoSans-500;
	font-style: normal;
}

.generic-content p sub {
	line-height: 1.5;
	font-size: 14px;
}

@media (min-width: 769px) {
	.generic-content, .generic-content p, .generic-content ul, .team-description p {
		font-size: 14px;
	}
}

@media (min-width: 992px) {
	.generic-content, .generic-content p, .generic-content ul, .team-description p {
		font-size: 20px;
	}
}

@media (min-width: 1200px) {
	.generic-content, .generic-content p, .generic-content ul, .team-description p {
		font-size: 23px;
	}
}

.modal.in .modal-dialog {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
}
.btn-group-vertical > .btn-group:after, .btn-group-vertical > .btn-group:before, .btn-toolbar:after, .btn-toolbar:before, .clearfix:after, .clearfix:before, .container-fluid:after, .container-fluid:before, .container:after, .container:before, .dl-horizontal dd:after, .dl-horizontal dd:before, .form-horizontal .form-group:after, .form-horizontal .form-group:before, .modal-footer:after, .modal-footer:before, .modal-header:after, .modal-header:before, .nav:after, .nav:before, .navbar-collapse:after, .navbar-collapse:before, .navbar-header:after, .navbar-header:before, .navbar:after, .navbar:before, .pager:after, .pager:before, .panel-body:after, .panel-body:before, .row:after, .row:before {
	display: table;
	content: " ";
}
.modal-header .close {
	margin-top: -2px;
}
button.close {
	-webkit-appearance: none;
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0;
}

.close {
	float: right;
	font-size: 21px;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	filter: alpha(opacity=20);
	opacity: .2;
}
.fade.in{
	opacity: 1;
}
.fade {
	opacity: 0;
	-webkit-transition: all .55s linear;
	-o-transition: all .55s linear;
	transition: all .55s linear;
}

.modal-content {
	position: relative;
	background-color: #fff;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	border: 1px solid #999;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	outline: 0;
	-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
	min-height: 350px;

	@include tablet {
		-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
	}
}
.team-members .team-highlight .modal-dialog .modal-body .person-bio .bio-img {
	@include tablet {
		float: left;
	}
}
.modal-backdrop.in {
	filter: alpha(opacity=50);
	opacity: .5;
}
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}
.collapse.in {
	display: block;
}
.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	-webkit-transition-timing-function: ease;
	-o-transition-timing-function: ease;
	transition-timing-function: ease;
	-webkit-transition-duration: .35s;
	-o-transition-duration: .35s;
	transition-duration: .35s;
	-webkit-transition-property: height,visibility;
	-o-transition-property: height,visibility;
	transition-property: height,visibility;
}

.modal.in {
	z-index: 1060;
	transition: all .55s linear;
}
.footer .breadcrumbs li a:not([href]):not([class]) {
	color: $white;
}
.footer .breadcrumbs li:last-child a:hover:hover {
	text-decoration: none;
	color:#fff;
}
.team-members .modal.fade:not(.in).right .modal-dialog {
	-webkit-transform: translate3d(125%, 0, 0);
	transform: translate3d(125%, 0, 0);
}
body .modal.fade .modal-dialog {
	transition: transform 0.55s ease-out;
	transform: translate(0, 0);
}
body .modal {
	z-index: -1;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	line-height: 1.1;
}
.panel-group .panel + .panel {
	margin-top: 5px;
}